@font-face {
  font-family: Hauora;
  src: url('./fonts/Hauora-Bold.woff2') format('woff2'),
    url('./fonts/Hauora-Bold.woff') format('woff');

  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Hauora;
  src: url('./fonts/Hauora-SemiBold.woff2') format('woff2'),
    url('./fonts/Hauora-SemiBold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Hauora;
  src: url('./fonts/Hauora-Regular.woff2') format('woff2'),
    url('./fonts/Hauora-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
