@import "../variables";

.class-table-contents {
  box-sizing: border-box;

  &__header {
    margin-top: 0;
    margin-bottom: 1em;

    &--completed {
      opacity: 0.2;
    }
  }

  &__block-header {
    margin-top: 0;
    margin-bottom: 1em;
  }

  &__link {
    display: flex;
    align-items: flex-start;
    color: #000000;
    &--active {
      color: #7A7A7A;
      font-weight: bold;
      text-decoration: none;
    }
  }

  &__icon {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    margin-right: 20px;
    padding-top: 5px;
    display: flex;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;

    &--active {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg' xmlns:serif='http://www.serif.com/' fill-rule='evenodd' clip-rule='evenodd'%3E%3Ccircle serif:id='shape 19' cx='12' cy='12' r='12'/%3E%3C/svg%3E");
    }
    &--check {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z'/%3E%3C/svg%3E");    }
    &--circle {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd'%3E%3Cpath d='M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11z'/%3E%3C/svg%3E");
    }
  }

}