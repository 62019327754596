// Root
//
// Ability to the value of the root font sizes, affecting the value of `rem`.
// null by default, thus nothing is generated.

:root {
  font-size: $font-size-root;
}

body {
  font-size: $font-size-base;
}
