@import '../variables';
@import '../mixins/btns.scss';

.bonus-block {
  box-sizing: border-box;
  padding-bottom: 60px;

  form {
    margin-top: 30px;
  }

  &__wrapper {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
  }

  &__h2 {
    @include h2;

    margin: 0 0 30px;
  }

  &__promocode {
    max-width: 388px;
    margin-bottom: 60px;
  }

  &__promocode-value {
    @include formInput;

    margin-bottom: 20px;
    display: block;
  }

  &__p {
    @include p1-thin;

    &--bold {
      @include p1;
    }

    margin: 0 0 20px;
  }

  &__note {
    max-width: 633px;
  }

  &__link {
    color: #000000;
  }

  &__btn {
    @include button-red;
  }
}
