@import '../mixins/btns.scss';

.promocode {
  box-sizing: border-box;
  margin-bottom: 60px;

  &__wrapper {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
  }

  &__form {
    max-width: 388px;
  }

  &__group {
    margin-bottom: 20px;
  }

  &__input {
    @include formInput;
  }

  &__error {
    @include input;

    color: color(brand_red);
  }

  &__success {
    @include input;

    color: color(brand_black);
  }

  &__btn {
    @include button-red;
  }
}
