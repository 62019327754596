@mixin alert-black() {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7.5px 20px;
  border-radius: 10px;
  background-color: color(brand_black);
  color: color(brand_white);
  box-sizing: border-box;
  flex-grow: 1;
}
