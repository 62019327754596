.page {
  width: 100%;
  box-sizing: border-box;

  &__title {
    text-align: center;
    font-size: 72px;
    line-height: 82px;
  }

  &__description {
    text-align: center;
    font-size: 22px;
    line-height: 32px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  &__course {
    min-height: 90%;
    flex-basis: 90%;
    flex-grow: 1;
    position: relative;
    overflow: hidden;
  }

  &__course-overlay {
    background-color: rgba(0, 0, 0, .6);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__course-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #7d7d7d;
    object-fit: cover;
  }

  &__info-wrapper {
    max-width: 640px;
    margin: auto;
  }
  &__course-info {
    color: #fff;
    position: absolute;
    top: 10vh;
    padding-right: 16px;
    max-width: 700px;
    text-align: center;
  }

  &__price {
    font-size: 18px;
    color: #ffffff;
    margin-top: 20px;
  }

  &__action-btn {
    padding: 10px 20px;
    color: #000000;
    background: #b7d7d2;
    border: none;
    transition: 0.3s ease;
    text-decoration: none;
    margin-top: 20px;
    display: inline-block;
    text-align: center;

    &:hover {
      opacity: 0.8;
    }
  }
}

