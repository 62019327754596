.settings {
  box-sizing: border-box;

  &__wrapper {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    padding: 0;
  }

  &__form {
    @include mediaQuery(desktop) {
      max-width: 388px;
    }
  }

  &__title {
    @include h1;

    margin: 0 0 30px;
    padding: 73px 0 0 0;
  }

  &__header {
    @include h2;

    margin: 0 0 30px;
    padding: 0;
  }

  &__group {
    margin-bottom: 20px;
  }

  &__input {
    @include formInput;
  }

  &__note {
    @include input;

    color: color(gray);
    margin-top: 10px;
  }

  &__error {
    @include input;

    color: color(brand_red);
    margin-top: 10px;
  }

  &__btn {
    @include button-red;
  }
}
