@import "../variables";

.footer {
  padding: 40px 0;

  &__wrapper {
    margin: 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media only screen and (max-width: $mobile-breakdown) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__info {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    white-space: nowrap;
    color: #7d7d7d;
    @media only screen and (max-width: $mobile-breakdown) {
      width: 100%;
      margin-top: 20px;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  &__contacts {
    color: #7d7d7d;
    @media only screen and (max-width: $mobile-breakdown) {
      text-align: center;
    }
  }

  &__links {
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: $mobile-breakdown) {
      margin: 0;
    }
  }

  &__link {
    color: #000000;
  }
}