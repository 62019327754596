.contacts {
  box-sizing: border-box;

  &__wrapper {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 40px 0;
  }

  &__header {
    text-align: center;
  }

}