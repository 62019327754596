html,
body {
  background-color: color(brand_gray);
  height: 100%;
  width: 100%;
}

body {
  font-family: $regular-font;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  margin: 0;
  padding: 0;
}

a {
  color: color(brand_black);
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: normal;
  margin: 0;
  padding: 0;
}

.noscroll {
  overflow: hidden;
  position: fixed;
}
