// Screens

/* device width */
$screens: (
  tablet: 768,
  desktop: 1024,
  desktop-lg: 1200,
) !default;

// Typography
$regular-font: Hauora, Arial, sans-serif !default;

// $font-size-root effects the value of `rem`, which is used for as well font sizes, paddings and margins
// $font-size-base effects the font size of the body text
$font-size-root: null !default;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
