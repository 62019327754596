@import "../variables";

.social-info {
  padding: 20px 0;

  &__wrapper {
    max-width: calc(100% - 20px);
    min-width: 320px;
    width: 100%;
    overflow: hidden;
    margin: 0 auto;

    @media only screen and (max-width: $tablet-breakdown) {
      padding: 0 20px;
      box-sizing: border-box;
    }
  }

  &__widget {
    position: relative;
    left: 0;
    right: 0;
    height: 350px;
    overflow: hidden;
    iframe {
      width: 2500px;
      height: 350px;
    }
  }

  &__items {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    @media only screen and (max-width: $tablet-breakdown) {
      flex-wrap: wrap;
      justify-content: space-between;
    }
    @media only screen and (max-width: $mobile-breakdown) {
      flex-direction: column;
      justify-content: center;
      align-content: center;
      align-items: center;
    }
  }

  &__item {

  }

  &__link {
    display: block;
    text-decoration: underline;
    color: #000000;
    font-weight: bold;
    font-size: 24px;
  }

  &__note {
    font-size: 12px;
    color: #7d7d7d;
  }

  &__menu {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    list-style: none;
    justify-content: space-between;
    padding: 0;

    @media only screen and (max-width: $mobile-breakdown) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__menu-item {
    @media only screen and (max-width: $mobile-breakdown) {
      margin: 20px 15px;
    }
  }

  &__menu-link {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 16px;
    color: #3C3C3C;

    &:hover {
      opacity: 0.6;
    }
  }
}