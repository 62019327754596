@import '../variables';

.content {
  box-sizing: border-box;
  margin: 70px 0;
  padding: 0 20px;
  padding: 0 20px 60px 20px;

  @include mediaQuery(desktop) {
    margin: 70px 0;
    padding: 0 0 60px;
  }
}
