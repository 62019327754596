.profile {
  box-sizing: border-box;

  &__wrapper {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    padding: 73px 0 0 0;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__title {
    @include h1;

    margin: 0 0 20px;
    padding: 0;
  }
}
