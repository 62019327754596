.purchased-items {
  box-sizing: border-box;

  &__wrapper {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;

    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 50px;
  }

  &__note {
    font-size: 20px;
  }

  &__row {
    display: flex;
    flex-flow: column;
    border-bottom: 1px solid #828282;
    padding: 20px 0;

    &:first-child {
      padding-top: 0;
    }

    @include mediaQuery(desktop) {
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  &__metadata,
  &__info {
    display: flex;
    flex-flow: column;

    @include mediaQuery(desktop) {
      width: calc(50% - 10px);
      flex-flow: row nowrap;
    }
  }

  &__info {
    margin-bottom: 20px;

    @include mediaQuery(desktop) {
      margin-bottom: 0;
    }
  }

  &__metadata {
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  &__action {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__link {
    margin-bottom: 20px;

    @include mediaQuery(desktop) {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }

  &__btn {
    @include button-red;

    display: none;
    text-decoration: none;
    flex-grow: 0;

    @include mediaQuery(desktop) {
      display: flex;
    }
  }

  &__title {
    @include p1;

    color: color(brand_black);
    text-decoration: none;
    margin: 0;
  }

  &__image {
    display: block;
    height: auto;
    border-radius: 10px;
    width: 100%;

    @include mediaQuery(desktop) {
      max-width: 143px;
      width: 143px;
    }
  }

  &__validtill {
    width: 50%;

    &:last-child {
      text-align: right;
    }

    @include mediaQuery(desktop) {
      text-align: right;
    }
  }
}
