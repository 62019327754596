@mixin size-regular() {
  @include button-text();

  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10.5px 20px;
  gap: 10px;
  text-transform: uppercase;
  border: 1px solid color(brand_black);
  border-radius: 10px;
  flex: none;
  flex-grow: 1;
  transition: background-color 0.1s ease-in;
}

@mixin button() {
  @include size-regular();
  background: transparent;
  color: color(brand_black);

  &:hover {
    background: color(brand_black);
    color: color(brand_white);
  }
}

@mixin button-invert() {
  @include size-regular();
  background: color(brand_black);
  color: color(brand_white);

  &:hover {
    background: transparent;
    color: color(brand_black);
  }
}

@mixin button-red() {
  @include size-regular();

  background: color(brand_red);
  border-color: color(brand_red);
  color: color(brand_white);

  &:hover {
    background: color(brand_white);
    color: color(brand_red);
  }
}

@mixin button-red-invert() {
  @include size-regular();
  border-color: color(brand_red);
  background: color(brand_white);
  color: color(brand_red);

  &:hover {
    background: color(brand_red);
    color: color(brand_white);
  }
}

@mixin button-fb() {
  @include size-regular();

  background: #415b93;
  border-color: #415b93;
  color: color(brand_white);

  &:hover {
    background: color(brand_white);
    color: #415b93;
  }
}

@mixin button-vk() {
  @include size-regular();

  background: #537ea4;
  border-color: #537ea4;
  color: color(brand_white);

  &:hover {
    background: color(brand_white);
    color: #537ea4;
  }
}
