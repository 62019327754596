@import '../mixins/btns.scss';

.payment-form {
  &__btn {
    @include button-red;

    text-decoration: none;
    display: inline-block;
  }

  &__note {
    @include p1-thin;
    margin: 0;
  }
}
