@import "../variables";

.class-page {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  &__header {
    margin: 0 0 1em 0;
  }

  &__content {
    flex-grow: 1;
  }

  &__navigation {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #c2c2c2;
    box-sizing: border-box;
    padding: 20px;
    max-width: 300px;
    background: #FFFFFF;
    transition: 0.5s left ease;

    @media only screen and (max-width: $tablet-breakdown) {
      position: fixed;
      left: -100%;
      z-index: 100;
      //display: none;

      &--open {
        width: 100%;
        max-width: 100%;
        top: calc(55px + 70px);
        bottom: 0;
        left: 0;
        border-right: none;
        overflow-y: scroll;
      }
    }
  }

  &__toolbar {
    height: 70px;
    max-height: 70px;
    overflow-y: hidden;
    background: #000000;
  }
}