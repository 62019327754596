@import "../variables";

.getting-started {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    background: #FF8787;
    color: #fff;
    font-weight: bold;
    margin: 20px 0;
    flex-direction: column;
    text-decoration: none;

    @media only screen and (max-width: $tablet-breakdown) {
        margin: 20px -20px;
    }

    &:visited, &:hover {
        color: #ffffff;
    }

    &__title {
        font-size: 2em;
        font-weight: bold;
        margin: 0;
        text-align: center;

        @media only screen and (max-width: $mobile-breakdown) {
            font-size: 1.5em;
        }    
    }

    &__description {
        margin: 0;
        font-weight: normal;
        font-size: 1em;
        text-align: center;

        @media only screen and (max-width: $mobile-breakdown) {
            font-size: 0.7em;
        }    
    }
}