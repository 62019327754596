@mixin formInput {
  @include input;

  background: transparent;
  color: color(gray);
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
  border: 1px solid #828282;
  border-radius: 10px;

  &:focus {
    border: 1px solid color(brand_black);
    color: color(brand_black);
    outline-color: color(brand_black);
  }
}
