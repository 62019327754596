@import '../variables';

.order {
  box-sizing: border-box;
  margin-bottom: 20px;

  &__wrapper {
    width: 100%;
    max-width: 960px;
    padding: 73px 0 0 0;
    margin: 0 auto;
  }

  &__alert {
    @include alert-black;

    margin-bottom: 20px;
  }

  &__border {
    padding: 20px;
    border: 1px solid color(brand_red);
    box-sizing: border-box;
    border-radius: 10px;
  }

  &__title {
    @include h1;

    text-transform: uppercase;
    margin-bottom: 20px;
  }

  &__p {
    @include p1-thin;

    margin-bottom: 20px;
    padding: 0;

    b {
      margin-right: 10px;
    }
  }

  &__important {
    text-decoration: underline;
    font-weight: bold;
  }

  &__description {
    color: #999999;
    margin: 1em 0;
  }

  &__note {
    margin: 20px 0;
    font-style: italic;
  }

  &__price-value {
    font-weight: bold;
    &--old {
      text-decoration: line-through;
    }
  }
}
