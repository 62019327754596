@use "sass:math";

@mixin mediaQuery($bpt) {
  @if (map_has_key($screens, $bpt)) {
    @media (min-width: #{math.div(map_get($screens, $bpt), 16)}em) {
      @content;
    }
  } @else {
    @error 'No breakpoint named #{$bpt}';
  }
}
