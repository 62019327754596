.header {
  box-sizing: border-box;
  border-bottom: 1px solid #e0e0e0;
  height: 70px;
  position: fixed;
  background: color(brand_gray);
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  @include mediaQuery(desktop) {
    border-bottom: none;
    height: 75px;
  }

  &--open {
    @include mediaQuery(desktop) {
      border: none;
    }
  }

  &__wrapper {
    box-sizing: border-box;
    max-width: 100%;
    min-width: 320px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 15px 20px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @include mediaQuery(desktop) {
      padding: 15px 30px;
    }
  }

  &__logo {
    display: flex;
    text-decoration: none;
    color: color(brand_black);
    background: url('./assets/logo.svg');
    background-repeat: no-repeat no-repeat;
    background-size: contain;
    font-size: 1rem;
    text-indent: -9999px;
    align-items: center;
    width: 120px;
    height: 35px;
  }

  &__menu {
    @include menu;

    display: none;
    flex-direction: row;
    flex-wrap: nowrap;
    list-style: none;
    padding: 0;
    margin: 0;
    align-self: center;
    display: none;

    @include mediaQuery(desktop) {
      display: flex;
    }
  }

  &__menu-item {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__menu-link,
  &__mobile-menu-link {
    text-decoration: none;
    color: color(brand_black);
  }

  &__user {
    display: none;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;

    @include mediaQuery(desktop) {
      display: flex;
    }
  }

  &__btn {
    @include button-red;

    text-decoration: none;
  }

  &__mobile-actions {
    display: block;

    @include mediaQuery(desktop) {
      display: none;
    }
  }

  &__mobile-btn {
    display: block;
    width: 40px;
    height: 40px;
    background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='2' y='12' width='36' height='1' fill='%23131313'/%3E%3Crect x='2' y='19.5' width='36' height='1' fill='%23131313'/%3E%3Crect x='2' y='27' width='36' height='1' fill='%23131313'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: 40px;
    background-position: center;

    .header--open & {
      display: none;
    }

    @include mediaQuery(desktop) {
      display: none;
    }
  }

  &__mobile-close {
    display: none;
    width: 40px;
    height: 40px;
    background-size: cover;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='7.62549' y='7' width='36' height='1' transform='rotate(45 7.62549 7)' fill='%23131313'/%3E%3Crect x='6.91846' y='32.4558' width='36' height='1' transform='rotate(-45 6.91846 32.4558)' fill='%23131313'/%3E%3C/svg%3E%0A");

    .header--open & {
      display: block;
    }
  }

  &__mobile-menu {
    @include menu;

    display: none;
    position: fixed;
    flex-direction: column;
    top: 70px;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    background: color(brand_gray);
    z-index: 100;
    overflow: hidden;
    transition: 0.5s bottom ease;
    padding: 20px 23px;
    box-sizing: border-box;

    .header--open & {
      display: flex;
    }
  }

  &__mobile-menu-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  &__mobile-menu-actions {
    flex-grow: 0;
    width: 100%;
  }

  &__mobile-menu-link {
    display: block;
    margin-bottom: 20px;
    color: color(brand_black);

    &:last-child {
      margin-bottom: 0;
    }
  }
}
