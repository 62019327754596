@import "../variables";

.class {
  box-sizing: border-box;

  &__wrapper {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 40px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    @media only screen and (max-width: $mobile-breakdown) {
      flex-direction: column;
    }
  }

  &__contents {
    flex-grow: 1;
    @media only screen and (max-width: $mobile-breakdown) {
      order: 2;
    }
    padding: 0 10px;
  }

  &__info {
    @media only screen and (max-width: $mobile-breakdown) {
      order: 1;
    }
    padding: 0 10px;
  }

  &__image {
    width: 100%;
    max-width: 300px;
    height: auto;
    @media only screen and (max-width: $mobile-breakdown) {
      max-width: 100%;
    }
  }

  &__header {
    margin-top: 0;
  }

  &__block-header {
    margin-top: 0;
  }
}