@import '../variables';

.auth {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  display: none;
  z-index: 100;

  @include mediaQuery(desktop) {
    z-index: 1000;
  }

  &--open {
    display: flex;
  }

  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    @include mediaQuery(desktop) {
      z-index: 1010;
      background: rgba(19, 19, 19, 0.4);
    }
  }

  &__modal {
    position: relative;
    background: color(brand_gray);
    width: 100%;
    z-index: 1100;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    align-items: center;

    @include mediaQuery(desktop) {
      display: block;
      border-radius: 10px;
      height: auto;
      max-width: 340px;
      background-color: color(brand_white);
    }
  }

  &__wrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
  }

  &__section {
    width: 100%;
  }

  &__form {
    width: 100%;
    text-align: center;
  }

  &__header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
  }

  &__close {
    display: block;
    text-decoration: none;
    background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_83_182)'%3E%3Crect x='7.57538' y='21.7175' width='20' height='1' transform='rotate(-45 7.57538 21.7175)' fill='%23828282'/%3E%3Crect x='21.7175' y='22.4246' width='20' height='1' transform='rotate(-135 21.7175 22.4246)' fill='%23828282'/%3E%3Ccircle cx='15' cy='15' r='14.5' stroke='%23828282'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_83_182'%3E%3Crect width='30' height='30' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    background-size: contain;
    width: 30px;
    height: 30px;
  }

  &__note {
    @include input;

    color: color(gray);
  }

  &__form-title {
    margin: 0;
  }

  &__form-column {
    width: calc(50% - 5px);
  }

  &__form-group {
    margin-bottom: 20px;
    width: 100%;

    &--submit {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__form-group-input {
    @include formInput;
  }

  &__form-group-label {
    display: none;
  }

  &__form-submit-btn {
    @include button-red;

    width: 100%;
  }

  &__form-action-btn {
    @include button;

    text-decoration: none;
    width: 100%;
  }

  &__form-link-btn {
    @include input;

    color: color(brand_black);
    text-decoration: none;
  }

  &__social-divider {
    border-top: 1px solid #828282;
    width: 100%;
  }

  &__social {
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &__social-btn {
    text-decoration: none;

    &--facebook {
      @include button-fb;
    }
    &--vkontakte {
      @include button-vk;
    }
  }

  .error {
    color: color(brand_red);
    margin-bottom: 20px;
  }
}
