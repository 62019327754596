@mixin button-text {
  font-family: $regular-font;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.1875rem;
}

@mixin h1 {
  font-family: $regular-font;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.0625rem;

  @include mediaQuery(desktop) {
    font-size: 1.875rem;
    line-height: 2.5625rem;
  }
}

@mixin h2 {
  font-family: $regular-font;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.0625rem;
}

@mixin p1 {
  font-family: $regular-font;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  font-style: normal;
  font-weight: 600;
}

@mixin p1-thin {
  @include p1;
  font-weight: 400;
}

@mixin menu {
  font-family: $regular-font;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 1.375rem;
  font-weight: 600;
  text-transform: uppercase;

  @include mediaQuery(desktop) {
    font-size: 1.125rem;
  }
}

@mixin input {
  font-family: $regular-font;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1.1875rem;
  font-weight: 600;
}
