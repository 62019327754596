@import '../variables';

.payment-selector {
  max-width: 388px;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 20px;

  &__variants {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 20px;
    width: 100%;
  }

  &__variant {
    border: 1px solid color(gray);
    display: flex;
    flex-flow: row nowrap;
    margin: 0 0 20px;
    padding: 20px;
    position: relative;
    border-radius: 10px;

    label {
      cursor: pointer;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__actions {
    align-self: flex-start;
  }

  &__action {
    display: none;

    &--active {
      display: block;
    }
  }

  &__radio {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;

    input {
      accent-color: rgb(206, 169, 169);
      padding: 0;
      margin: 0;
      appearance: none;
      background-color: color(brand_gray);
      font: inherit;
      color: currentColor;
      width: 1.15em;
      height: 1.15em;
      border: 1px solid color(brand_black);
      border-radius: 50%;
      transform: translateY(-0.075em);
      display: grid;
      place-content: center;

      &::before {
        content: '';
        width: 0.65em;
        height: 0.65em;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 10px 10px color(brand_black);
      }

      &:checked::before {
        transform: scale(1);
      }
    }
  }

  &__variant-title {
    @include p1;
    padding: 0;
  }

  &__variant-description {
    @include p1-thin;
    padding: 0;
  }
}
