@import "../variables";

.products {
  &__header {
    text-align: center;
  }

  &__items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__item {
    display: block;
    padding: 0;
    position: relative;
    box-sizing: border-box;
    width: calc(25% - 10px);
    margin: 20px 10px 20px 10px;

    &--featured {
      margin: 0 20px 0 0;
      width: calc(50% - 10px);
      min-height: 400px;
      &:nth-child(odd) {
        margin-left: 0;
        margin-right: 10px;
      }
      &:nth-child(even) {
        margin-left: 10px;
        margin-right: 0;
      }

      &:nth-child(n+3) {
        margin-top: 10px;
      }

      @media only screen and (max-width: $tablet-breakdown) {
        width: 100%;
        margin-bottom: 20px;

        &:nth-child(odd) {
          margin-left: 0;
          margin-right: 0;
        }
        &:nth-child(even) {
          margin-left: 0;
          margin-right: 0;
        }

        &:nth-child(n+3) {
          margin-top: 0;
        }
      }

      @media only screen and (max-width: $mobile-breakdown) {
        min-height: 200px;
      }

    }
  }

  &__item-link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-size: cover;
  }

  &__item-metadata {
    position: absolute;
    top: 40%;
    bottom: 0;
    right: 0;
    left: 0;
    color: #FFFFFF;
    z-index: 20;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;

    @media only screen and (max-width: $tablet-breakdown) {
      font-size: 12px;
    }

    @media only screen and (max-width: $mobile-breakdown) {
      top: 15%;
    }
  }

  &__item-title {
    font-size: 1.8em;
    text-align: center;
  }

  &__item-description {
    font-size: 1.5em;
    text-align: center;
  }

  &__item-price {
    font-weight: bold;
    max-width: 30%;
    @media only screen and (max-width: $mobile-breakdown) {
      max-width: 90%;
    }
  }
}

.product {
  display: block;
  padding: 0;
  position: relative;
  box-sizing: border-box;
  width: calc(25% - 20px);
  margin: 20px 10px 20px 10px;

  @media only screen and (max-width: $tablet-breakdown) {
    width: calc(50% - 20px);
  }

  @media only screen and (max-width: $tablet-breakdown) {
    width: 100%;
    margin: 20px 0;
  }

  &__image {
    width: 100%;
    height: auto;
  }

  &__link {
    display: block;
    text-decoration: none;
  }

  &__metadata {
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    margin-top: 10px;

    @media only screen and (max-width: $tablet-breakdown) {
      font-size: 12px;
    }
  }

  &__title {
    font-size: 1.2em;
    text-align: center;
    margin: 0;
  }

  &__description {
    font-size: 1em;
    text-align: center;
  }

  &__price {
    text-decoration: underline;
    font-style: italic;
    margin-top: 15px;
  }

}
