@import "../variables";

.class-navigation {
  position: sticky;
  top: 55px;
  z-index: 99;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  background: #000;
  color: #FFFFFF;
  height: 70px;
  max-height: 70px;
  overflow-y: hidden;

  @media only screen and (max-width: $mobile-breakdown) {
    max-width: 100%;
  }

  &__next, &__prev {
    flex-basis: 25%;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    order: 2;

    @media only screen and (max-width: $mobile-breakdown) {
      flex-grow: 1;
      order: 3;
    }
  }

  &__next {
    text-align: right;
    justify-content: flex-end;
    order: 4;
  }

  &__link {
    cursor: pointer;
    border: none;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    padding: 10px 20px;
    background: #000;
    color: #FFFFFF;
    height: 100%;
    box-sizing: border-box;

    &--all {
      display: flex;
      align-items: center;
      justify-content: center;
      @media only screen and (max-width: $mobile-breakdown) {
        display: none;
      }
    }

    &:hover {
      opacity: 0.8;
    }
    &:visited {
      color: #FFFFFF;
    }
  }

  &__link-text {
    @media only screen and (max-width: $mobile-breakdown) {
      display: none;
    }
  }

  &__counter {
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-basis: 50%;
    order: 3;
    @media only screen and (max-width: $mobile-breakdown) {
      flex-grow: 1;
      flex-basis: 100%;
      order: 2;
    }
  }

  &__menu {
    width: 40px;
    height: 100%;
    display: none;
    color: #FFFFFF;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd'%3E%3Cpath d='M24 18v1h-24v-1h24zm0-6v1h-24v-1h24zm0-6v1h-24v-1h24z' fill='%23FFF'/%3E%3Cpath fill='%23FFF' d='M24 19h-24v-1h24v1zm0-6h-24v-1h24v1zm0-6h-24v-1h24v1z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    padding: 0 20px;
    order: 1;

    @media only screen and (max-width: $tablet-breakdown) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--open {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd'%3E%3Cpath fill='%23FFFFFF' d='M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z'/%3E%3C/svg%3E");
    }
  }

  &__icon {
    width: 40px;
    height: 40px;
    display: none;
    background-repeat: no-repeat;
    background-position: center;
    flex-shrink: 0;

    &--prev {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd'%3E%3Cpath fill='%23FFF' d='M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm-4.828 11.5l4.608 3.763-.679.737-6.101-5 6.112-5 .666.753-4.604 3.747h11.826v1h-11.828z'/%3E%3C/svg%3E");
    }
    &--next {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd'%3E%3Cpath fill='%23ffffff' d='M12 0c-6.623 0-12 5.377-12 12s5.377 12 12 12 12-5.377 12-12-5.377-12-12-12zm0 1c-6.071 0-11 4.929-11 11s4.929 11 11 11 11-4.929 11-11-4.929-11-11-11zm4.828 11.5l-4.608 3.763.679.737 6.101-5-6.112-5-.666.753 4.604 3.747h-11.826v1h11.828z'/%3E%3C/svg%3E");
    }

    @media only screen and (max-width: $mobile-breakdown) {
      display: block;
    }
  }
}