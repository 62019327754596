@import '../../styles/variables';

.profile-menu {
  box-sizing: border-box;
  margin-bottom: 60px;
  margin-left: -20px;
  width: calc(100% + 40px);
  overflow-x: scroll;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  @include mediaQuery(desktop) {
    width: 100%;
    overflow-x: auto;
    margin-left: 0;
  }

  &__wrapper {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    padding-left: 20px;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;

    &::after {
      content: '';
      flex-shrink: 0;
      width: 20px;

      @include mediaQuery(desktop) {
        width: 0;
      }
    }

    @include mediaQuery(desktop) {
      padding-left: 0;
    }
  }

  &__logout {
    @include button-invert;

    flex-grow: 0;
    flex-shrink: 0;
    text-decoration: none;
    position: inherit;

    @include mediaQuery(desktop) {
      position: absolute;
      right: 0;
    }
  }

  &__items {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    padding-right: 20px;
    position: relative;

    @include mediaQuery(desktop) {
      padding-right: 0;
      width: 100%;
    }
  }

  &__item {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__link {
    @include button-red-invert;

    text-decoration: none;
    white-space: nowrap;

    &--active {
      @include button-red;
    }
  }
}
